import * as React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { Container, Box, Flex, NavLink } from '@theme-ui/components'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Grid, Divider, Button, Card, Text} from '@theme-ui/components';
import NavBar from '../components/NavBar';
import { graphql } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby'
import { Link } from '@theme-ui/components'
import Seo from "../components/seo";
import Footer from '../components/footer';


import Layout from "../components/layout"


const CoursePostTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  if(post.frontmatter.contentCategory === "Course") {
    const myameCourse = `/myame/course/${post.frontmatter.courseID}`
  return (
    <div>
    <Seo />
      <NavBar />
      
      <Container>
      <Grid columns={[2,2,2,2]} gap={4}>
      <Box>
      <div style={{padding:'56.25% 0 0 0', position:'relative'}}><iframe src={post.frontmatter.trailer} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute', top:'0', left:'0', width:'100%', height:'100%'}} title={post.frontmatter.subTitle}></iframe></div>
      <Text py={3} as="p" style={{
        fontSize: '12px'
      }}>
        <Text sx={{
          color: 'secondary'
        }} as="h3">Video Preview:</Text> {post.frontmatter.title} - {post.frontmatter.subTitle}
      </Text>
      </Box>
      <Box>
      <div style={{padding:'56.25% 0 0 0', position:'relative'}}><iframe src={post.frontmatter.executiveSummary} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute', top:'0', left:'0', width:'100%', height:'100%'}} title={post.frontmatter.subTitle}></iframe></div>
      <Text py={3} as="p" style={{
        fontSize: '12px'
      }}>
        <Text sx={{
          color: 'secondary'
        }} as="h3">Video Executive Summary:</Text> {post.frontmatter.title} - {post.frontmatter.subTitle}
      </Text>
      </Box>
      </Grid>
      <Divider my={3} sx={{
      backgroundColor: 'primary',
    }}/>
      <Text py={3} as="h1">
        {post.frontmatter.title} - {post.frontmatter.subTitle}
      </Text>
      <Grid p={2} columns={[1, 1, 3]}>
      <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Category:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.category}</Text>
        </Box>
        <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Company:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.company}</Text>
        </Box>
        <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Instructors:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.author}</Text>
        </Box>
        <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Course Length:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.courseLength}</Text>
        </Box>
        <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Course Materials:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.courseMaterials}</Text>
        </Box>
        <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Number of Modules:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.modules.length}</Text>
        </Box>
      </Grid>
      <Box p={2}>
      <Button sx={{ color: 'text', textDecoration: 'none', fontWeight: '500'}} to={myameCourse} as={GatsbyLink}>Find this course on your dashboard</Button>
      </Box>
      
      <Divider sx={{
        backgroundColor: 'primary',
        marginBottom: '60px',
      }}
      />
      <MDXRenderer>{post.body}</MDXRenderer>

    

      <ol>
      {post.frontmatter.modules.map(post => {

        return (
          <li style={{paddingBottom: '30px'}}>
            <Text as="h3">
              {post.title}
              </Text>
              <Text as="p" sx={{
                color: 'text',
                fontSize: '12px',
              }}>
              Module Type: {post.moduleType} | Duration: {post.moduleLength} | Module ID: {post.moduleID}
              </Text>
              <Divider sx={{
                backgroundColor: 'primary',
              }} />
              <Text as="p">
                {post.content}
              </Text>
          </li>
        )
        })}
        </ol>
        <Divider sx={{
          backgroundColor: 'primary',
        }}/>
        <Text py={3} as="h2">
        Course Takeaways - {post.frontmatter.title} - {post.frontmatter.subTitle}
        </Text>
        <ul>
      {post.frontmatter.courseTakeaways.map(post => {

        return (
          <li>
            <Text as="p">
              {post.takeAway}
              </Text>
          </li>
        )
        })}
        </ul>
        <Divider sx={{
          backgroundColor: 'primary',
        }}/>
        <Text py={3} as="h2">
        Who Should Take This Training?
        </Text>
        <ul>
      {post.frontmatter.candidates.map(post => {

        return (
          <li>
            <Text as="p">
              {post.candidate}
              </Text>
          </li>
        )
        })}
        </ul>

        <Box p={2} bg="muted" sx={{}}>
        <Link href={post.frontmatter.backLink} target="_blank">
        <GatsbyImage image={getImage(post.frontmatter.courseAdvert)} style={{ marginBottom: '40px'}} />
        </Link>
        <Text as="h3" color='secondary'>
          About {post.frontmatter.company}
        </Text>
        {post.frontmatter.companyInfo.map(post => {

          return (
            <>
              <Text py={2} as="p">
                {post.info}
                </Text>
                <Link href={post.companyURL}>
                {post.companyURL}
                </Link>
                </>
          )
          })}
        </Box>

        <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link as={GatsbyLink} to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title} - {previous.frontmatter.subTitle}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link as={GatsbyLink} to={next.fields.slug} rel="next">
                {next.frontmatter.title} - {next.frontmatter.subTitle} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      </Container>
      <Footer />
    </div>
  )
            } else if(post.frontmatter.contentCategory === "Technology Partner") {
              return (
                <div>
                <Seo />
                  <NavBar />
                  
                  <Container>
                  <div style={{padding:'56.25% 0 0 0', position:'relative'}}><iframe src={post.frontmatter.trailer} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute', top:'0', left:'0', width:'100%', height:'100%'}} title={post.frontmatter.subTitle}></iframe></div>
                  
                  <Text py={3} as="h1">
                    <span sx={{
                      color: '#ffcc00'
                    }}>Technology Partner: </span>{post.frontmatter.title} - {post.frontmatter.subTitle}
                  </Text>
                  <Grid p={2} columns={[1, 1, 3]}>
                  <Box p={2} bg="muted" sx={{
                      color: 'secondary',
                      fontWeight: '700',
                      fontSize: '12px',
                    }}>Category:<br />
                    <Text sx={{
                      color: 'text',
                      fontWeight: '400',
                    }}>{post.frontmatter.category}</Text>
                    </Box>
                    <Box p={2} bg="muted" sx={{
                      color: 'secondary',
                      fontWeight: '700',
                      fontSize: '12px',
                    }}>Company:<br />
                    <Text sx={{
                      color: 'text',
                      fontWeight: '400',
                    }}>{post.frontmatter.company}</Text>
                    </Box>
                    <Box p={2} bg="muted" sx={{
                      color: 'secondary',
                      fontWeight: '700',
                      fontSize: '12px',
                    }}>Contacts:<br />
                    <Text sx={{
                      color: 'text',
                      fontWeight: '400',
                    }}>{post.frontmatter.author}</Text>
                    </Box>
                    </Grid>
                    <Divider sx={{backgroundColor:'primary'}} />
                    {/*<Text py={3} as="h3">
                      AME Courses Offered by {post.frontmatter.company}
                    </Text>
                    <Grid p={2} columns={[1, 1, 3]}>
                    {post.frontmatter.techPartnerOfferedCourses.map(post => {
                        
                        return (
                          <Card key={post.slug}
                            sx={{
                              maxWidth: '100%',
                              padding: 1,
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'primary',
                            }}>

                            <Text as="h3" p={2} sx={{
                              color: 'secondary',
                            }}>{post.techPartnerOfferedCourseTitle}</Text>
                            
                                  <Link p={2} sx={{ color: 'primary' }} to={post.slug} as={GatsbyLink}>
                      Learn More...
                    </Link>
                            
                            </Card>
                         
                        )
                        }
                    )}
                        
                    {post.frontmatter.techPartnerAddCourses.map(addCourse => {
                    return (
                      <Card key={post.slug}
                            sx={{
                              maxWidth: '100%',
                              padding: 1,
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'primary',
                            }}>

                            <Text as="h3" p={2} sx={{
                              color: 'secondary',
                            }}>{addCourse.addedCourseTitle}</Text>
                            
                                  <Link p={2} sx={{ color: 'primary' }} to={post.slug} as={GatsbyLink}>
                      Learn More...
                    </Link>
                            
                            </Card>
                    )
                  })}
                   
                  </Grid>
                  
                  
                  <Divider sx={{
                    backgroundColor: 'primary',
                    marginBottom: '60px',
                  }}
                  />
                  */}
                  
                    
              
                  <MDXRenderer>{post.body}</MDXRenderer>
            
                
            
                  {/*<ol>
                  {post.frontmatter.modules.map(post => {
            
                    return (
                      <li style={{paddingBottom: '30px'}}>
                        <Text as="h3">
                          {post.title}
                          </Text>
                          <Text as="p" sx={{
                            color: 'text',
                            fontSize: '12px',
                          }}>
                          Module Type: {post.moduleType} | Duraction: {post.moduleLength} | Module ID: {post.moduleID}
                          </Text>
                          <Divider sx={{
                            backgroundColor: 'primary',
                          }} />
                          <Text as="p">
                            {post.content}
                          </Text>
                      </li>
                    )
                    })}
                    </ol>
                    <Divider sx={{
                      backgroundColor: 'primary',
                    }}/>
                    <Text py={3} as="h2">
                    Course Takeaways - {post.frontmatter.title} - {post.frontmatter.subTitle}
                    </Text>
                    <ul>
                  {post.frontmatter.courseTakeaways.map(post => {
            
                    return (
                      <li>
                        <Text as="p">
                          {post.takeAway}
                          </Text>
                      </li>
                    )
                    })}
                    </ul>
                    <Divider sx={{
                      backgroundColor: 'primary',
                    }}/>
                    <Text py={3} as="h2">
                    Who Should Take This Training?
                    </Text>
                    <ul>
                  {post.frontmatter.candidates.map(post => {
            
                    return (
                      <li>
                        <Text as="p">
                          {post.candidate}
                          </Text>
                      </li>
                    )
                    })}
                    </ul>
                    */}
                    <Box p={2} bg="muted" sx={{}}>
                    <Link href={post.frontmatter.backLink} target="_blank">
                    <GatsbyImage image={getImage(post.frontmatter.courseAdvert)} style={{ marginBottom: '40px'}} />
                    </Link>
                    <Text as="h3" color='secondary'>
                      About {post.frontmatter.company}
                    </Text>
                    {post.frontmatter.companyInfo.map(post => {
            
                      return (
                        <>
                          <Text py={2} as="p">
                            {post.info}
                            </Text>
                            <Link href={post.companyURL}>
                            {post.companyURL}
                            </Link>
                            </>
                      )
                      })}
                    </Box>
            
                    <nav className="blog-post-nav">
                    <ul
                      style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                      }}
                    >
                      <li>
                        {previous && (
                          <Link as={GatsbyLink} to={previous.fields.slug} rel="prev">
                            ← {previous.frontmatter.title} - {previous.frontmatter.subTitle}
                          </Link>
                        )}
                      </li>
                      <li>
                        {next && (
                          <Link as={GatsbyLink} to={next.fields.slug} rel="next">
                            {next.frontmatter.title} - {next.frontmatter.subTitle} →
                          </Link>
                        )}
                      </li>
                    </ul>
                  </nav>
                  </Container>
                  <Footer />
                </div>
              )
                        }
}

export default CoursePostTemplate

export const pageQuery = graphql`
  query CoursePostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        subTitle
        company
        author
        category
        contentCategory
        techPartnerOfferedCourses {
          techPartnerOfferedCourseTitle
          techPartnerOfferedCourseLink
        }
        courseLength
        courseMaterials
        companyInfo {
          info
          companyURL
        }
        courseTakeaways {
          takeAway
        }
        candidates {
          candidate
        }
        techPartnerAddCourse
        techPartnerAddCourses {
          addedCourseFeature
          addedCourseTitle
          addedCourseCategory
          addedCourseInstructor
          addedCourseVideo
        }
        modules {
          moduleNumber
          title
          content
          moduleID
          moduleLength
          moduleType
        }
        date(formatString: "MMMM DD, YYYY")
        excerpt
        trailer
        executiveSummary
        backLink
        courseID
        courseAdvert {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        subTitle
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        subTitle
      }
    }
  }
`
